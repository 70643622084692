import React, { useState, useEffect } from 'react';
import { getGlobalstate, SubscribeGlobalState, SubscribeClickOutside } from '../infrastructure/events';
import { SubmitBtn } from '../components/Elements';
import { SizedImage, CustomLink, RedirectBtn } from './Elements'
import { AuthService } from '../infrastructure/services';
import { Feed } from "../pages/Feed";

const authService = new AuthService();

export const PageContainer: React.FC<{
    child?: JSX.Element,
    w?: string,
    checkAuth?: boolean
}> = props => {
    const child = props.child;
    const user = SubscribeGlobalState("authorizedUser");

    if (props.checkAuth && !user) {
        window.location.pathname = '/';
    }

    return <div>
        <Header />
        <main className={`flex-1 mx-auto text-20 mb-10 mt-8 ${props.w || 'w-[40%]'}`}>
            {child}
        </main>
        <div id="content-wrap">
        </div>
        <Footer />
    </div>
}

export const Header: React.FC = () => {
    const user = SubscribeGlobalState("authorizedUser");

    return <header className='relative bg-blue h-[80px] text-white flex items-center text-16'>
        <div className='w-[80%] mx-auto flex flex-row justify-between items-center'>
            <CustomLink href='/' className='flex' child={<SizedImage className='h-[25px]' src='/images/logo.webp' />} />
            <CustomLink href='/feed' className='flex' child={user ? 'Моя лента' : 'Лента'} />
            <CustomLink href='/doctors' className='flex' child='Врачи' />
            <CustomLink href='/articles' className='flex' child='Статьи' />
            {
                user
                    ? <CustomLink href='/consultations' className='flex' child='Консультации' />
                    : <></>
            }
            {
                user
                    ? <CustomLink href='/favorite' className='flex' child='Избранное' />
                    : <></>
            }
            {
                user
                    ? <CustomLink href='/files' className='flex' child='Файлы' />
                    : <></>
            }
            <div className='flex flex items-center'>
                {
                    user?.role == 'Doctor'
                        ? <RedirectBtn
                            className='mr-2'
                            sizeType='minimal'
                            colorType='dark'
                            href='/articles/new'
                            textElement={
                                <SizedImage
                                    className='h-[22px]'
                                    src={`/images/icons/new-article.png`} />}
                        />
                        : <></>
                }
                <CustomLink href='/profile' className='flex' hrefClassName='text-bold' child={user?.shortName} />
                {
                    user
                        ? <SubmitBtn textElement='Выйти' className='ml-3 flex text-bold'
                            sizeType='short' colorType='white'
                            onClick={() => authService.remove()}
                        />
                        : <RedirectBtn href='/auth'
                            className='ml-3 flex text-bold'
                            sizeType='short' colorType='white'
                            textElement={'Регистрация • Вход'}
                        />
                }
            </div>
        </div>
    </header>
}

const Footer: React.FC = () => {
    return <footer className='mt-20'>
        <div className='bg-[#f6f2ef] mt-20'>
            <div className='w-[60%] flex items-center mx-auto h-[150px] justify-between'>
                <div className='text-[16px] '>
                    <p className='text-sbold'>Проект реализуется при финансовой поддержке фонда содействия развитию малых форм предприятий</p>
                    <p className='text-sbold'>в научно-технической сфере (Фонд содействия инновациям) в рамках программы "Студенческий стартап"</p>
                    <p className='text-sbold'>федерального проекта «Платформа университетского технологического предпринимательства</p>
                </div>
                <SizedImage
                    className='h-[120px] w-[166px]'
                    src='/images/putp_logo.png'
                />
                <SizedImage
                    className='h-[70px] w-[136px]'
                    src='/images/fond_rf.png'
                />
            </div>
        </div>
        <div className='bg-[#111111] h-[170px]'>
            <div className='w-[60%] flex items-center mx-auto h-[150px] justify-between text-[#bcbec0] text-[12px]'>
                <div>
                    <SizedImage className='h-[25px]' src='/images/logo.webp' />
                    <p className='mt-3'>соединяем врачей и пациентов</p>
                </div>
                <div>
                    <p className='mt-3'>на сайте используются изображения с сайтов</p>
                    <p className=''>www.freepic.com и www.flaticon.com</p>
                </div>
                <div className='flex flex-col items-center'>
                    <a className='flex flex-row items-center' href="https://t.me/doqudo" target='_blank'>
                        <SizedImage className='h-[16px] mr-2' src='/images/icons/tg_footer.webp' />
                        <div>doqudo</div>
                    </a>
                    <div className='flex flex-row items-center'>
                        <SizedImage className='h-[16px] mr-2' src='/images/icons/mail_footer.webp' />
                        <div>doqudo@ya.ru</div>
                    </div>
                </div>
                <div>
                    <div>© 2024 OOO Сервис МедИнфо</div>
                    <a href='http://www.litterae.tilda.ws/'>
                        <div className='flex flex-row items-center'>
                            <div className=''>© задизайнено</div>
                            <SizedImage className='h-[11px] ml-2' src='/images/logo_litterae.webp' />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </footer>
}