import { Routes, Route, NavLink, useSearchParams, useLocation } from "react-router-dom"
import { Auth } from './pages/Auth';
import { Doctors } from './pages/Doctors';
import { User } from './pages/User';
import { BaseEmptyPage, NotFound } from './pages/BasePages';
import { Article } from "./pages/Article";
import { Articles } from "./pages/Articles";
import { Feed } from "./pages/Feed";
import { Favorite } from "./pages/Favorite";
import { Profile } from "./pages/Profile";
import { NewArticle } from "./pages/NewArticle";
import { EditArticle } from "./pages/EditArticle";
import { UserArticles } from "./pages/UserArticles";
import { Consultations } from "./pages/Consultations";
import { Consultation } from "./pages/Consultation";
import { Files } from "./pages/Files";
import { SearchArticleResultPopup, NewFilePopup, NewConsultation } from "./components/Popups";

export const App = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const popup = searchParams.get("popup") as TPopupType

  return <>
    <Routes>
      <Route path="/" element={<Feed />} />
      <Route path="/auth" element={<Auth />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/feed" element={<Feed />} />
      <Route path="/doctors" element={<Doctors />} />
      <Route path="/users/:id" element={<User />} />
      <Route path="/users/:id/articles" element={<UserArticles />} />
      <Route path="/articles" element={<Articles />} />
      <Route path="/articles/new" element={<NewArticle />} />
      <Route path="/articles/:id" element={<Article />} />
      <Route path="/articles/:id/edit" element={<EditArticle />} />
      <Route path="/favorite" element={<Favorite />} />
      <Route path="/files" element={<Files />} />
      <Route path="/consultations" element={<Consultations />} />
      <Route path="/consultations/:id" element={<Consultation />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
    {renderPopup(popup)}
  </>
}

function renderPopup(popup: TPopupType) {
  switch (popup) {
    case "search_result":
      return <SearchArticleResultPopup />
    case "new_file":
      return <NewFilePopup />
    case "new_consultation":
      return <NewConsultation />
    default:
      return undefined;
  }
}