import { PageContainer } from '../components/Main';
import { SizedImage, RedirectBtn, TextLikeBtn, TextWithIcon, CustomLink, ApiRequestForm, SubmitBtn, FileDnD } from '../components/Elements';
import { NotFound, ErrorPage } from './BasePages';
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { toDate } from "../infrastructure/utils"
import { UsersRepository, ArticlesRepository } from "../api/BackendRepositories";
import { SubscribeGlobalState } from "../infrastructure/events"

const usersRepository = new UsersRepository();
const articlesRepository = new ArticlesRepository();

export const Profile: React.FC = () => {
    const [userResponse, setUserResponse] = useState<TResponseWithData<TProfileUser> | undefined>();

    const enums = SubscribeGlobalState('enums');
    const specializations = enums ? enums['DoctorSpecializationType'] : [];

    useEffect(() => {
        usersRepository.getProfileUser()
            .then(v => setUserResponse(v));
    }, []);

    return <PageContainer
        checkAuth={true}
        w='w-[50%]'
        child={
            <div>
                {userResponse
                    ? userResponse.hasErrors
                        ? <ErrorPage errors={userResponse.errors} withBase={false} />
                        : <UserContainer specializations={specializations} user={userResponse?.data!} />
                    : <div></div>}
            </div>
        }
    />
}

const UserContainer: React.FC<{
    user: TProfileUser
    specializations: TKeyValuePair<string, string>[]
}> = props => {
    const user = props.user;

    const formInputs = [{
        label: 'Имя',
        type: 'text',
        propName: 'Name',
        placeholder: '',
        defaultValue: user.name,
        className: 'mt-4'
    },
    {
        label: 'Фамилия',
        type: 'text',
        propName: 'Lastname',
        placeholder: '',
        defaultValue: user.lastname,
        className: 'mt-4',
    },
    {
        label: 'Отчество',
        type: 'text',
        propName: 'Surname',
        placeholder: '',
        defaultValue: user.surname,
        className: 'mt-4',
    },
    {
        label: 'О себе',
        type: 'text',
        propName: 'Description',
        placeholder: '',
        defaultValue: user.description,
        className: 'mt-4',
    },
    {
        type: 'email',
        propName: 'Email',
        placeholder: '',
        defaultValue: user.email,
        className: 'mt-4',
        label: 'Почта'
    }] as {
        className?: string,
        type?: string,
        placeholder: string
        propName: string,
        isRequired?: boolean,
        defaultValue?: string,
        label?: string,
        selectionValues?: TKeyValuePair<string, string>[]
    }[];

    if (user.doctor) {
        // formInputs.push({
        //     label: 'Место работы',
        //     type: 'text',
        //     propName: 'WorkPlace',
        //     placeholder: '',
        //     defaultValue: user.doctor.workPlace,
        //     className: 'mt-4',
        // });
        formInputs.push({
            label: 'Специальность',
            type: 'selection',
            propName: 'Specialization',
            placeholder: '',
            defaultValue: user.doctor.specialization,
            className: 'mt-4',
            selectionValues: props.specializations.filter(f => f.key != 'All')
        });
        formInputs.push({
            label: 'Направление',
            type: 'text',
            propName: 'Direction',
            placeholder: '',
            defaultValue: user.doctor.direction,
            className: 'mt-4',
        });
        formInputs.push({
            label: 'Номер сертификата',
            type: 'text',
            propName: 'CertificateNumber',
            placeholder: '',
            defaultValue: user.doctor.certificateNumber,
            className: 'mt-4',
        });

    }

    return <div>
        <div className='flex flex-row'>
            <div className='mr-10 '>
                <SizedImage
                    src={user.photoPath}
                    fromCdn={true}
                    className='w-[300px] h-[300px]'
                    imgClassName='rounded-full object-cover object-top'
                    templateIfSrcEmpty='/images/article_placeholder.png'
                />
            </div>
            <div className=''>
                <div className='text-36'>
                    <span className='text-sbold'>{user.lastname}</span>
                    <span className='text-sbold'> {user.name}</span>
                    {user.surname ? <span className='text-sbold'> {user.surname}</span> : <></>}
                </div>
                <div className='text-24 text-regular mt-2 flex'>
                    {user.isDoctor ? user.doctor?.direction : 'Пациент'}
                </div>
                {user.description ? <div className='text-18 text-regular mt-8 flex'>
                    {user.description}
                </div> : <></>}
                <div className='mt-[60px] flex'>
                    {
                        user.isDoctor
                            ? <TextLikeBtn className='ml-2' colorType='gray'
                                textElement={
                                    <TextWithIcon
                                        className=''
                                        text={`${user.followersCount} Подписчика`}
                                        src={`/images/icons/followers.webp`} />}
                            />
                            : <></>
                    }
                    <TextLikeBtn className='ml-2' colorType='gray'
                        textElement={
                            <TextWithIcon
                                className=''
                                text={`${user.followsCount} Подписок`}
                                src={`/images/icons/followers.webp`} />}
                    />
                </div>
            </div>
        </div>
        <hr className='my-10' />
        <div>
            <p className='text-[24px]'>Обновить фото</p>
            <FileDnD
                accept={{
                    'image/jpeg': [],
                    'image/jpg': [],
                    'image/png': [],
                    'image/webp': [],
                }}
                labelText='Загрузить изображение'
                className='mt-4'
                maxSizeBytes={10000000}
                onDropAccepted={(files) => updateArticleImg(files)}
                onDropRejected={(rejections) => console.log('rejections', rejections)}
            />
        </div>
        <hr className='my-10' />
        <div>
            <p className='text-[24px]'>Личные данные</p>
            {
                ApiRequestForm<TUpdateProfile, TResponse>(
                    (request) => usersRepository.updateProfile(request),
                    (request, response) => window.location.reload(),
                    formInputs,
                    undefined,
                    undefined,
                    'Обновить',
                    'dark'
                )
            }
        </div>
        <hr className='my-10' />
        <div>
            <p className='text-[24px]'>Безопасность</p>
            {
                ApiRequestForm<TUpdatePassword, TResponse>(
                    (request) => usersRepository.updatePassword(request),
                    (request, response) => {
                        if (!response.hasErrors) {
                            alert('Пароль успешно обновлен')
                        }
                    },
                    [{
                        type: 'password',
                        propName: 'Password',
                        placeholder: 'Старый пароль',
                        isRequired: true,
                        className: 'mt-4'
                    },
                    {
                        type: 'password',
                        propName: 'NewPassword',
                        placeholder: 'Новый пароль',
                        isRequired: true,
                        className: 'mt-4'
                    }],
                    undefined,
                    undefined,
                    'Обновить',
                    'dark'
                )
            }
        </div>
    </div>

    function updateArticleImg(files?: any) {
        const file = files ? files[0] : undefined;

        if (!file) {
            return;
        }

        usersRepository.updatePhoto(file)
            .then(t => window.location.reload());
    }
}