export function isNothing(value: any) {
    return value === undefined || value === null || value == ''
}

export function toDate(value: string): string {
    return new Date(value).toLocaleDateString()
}

export function toDateWithTime(value: string): string {
    const date = new Date(value);

    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}` 
}

export function getEnumRuValue(enums: TKeyValuePair<string, string>[], value: string): string | undefined {
   return enums.find(f => f.key == value)?.value;
}

export function convertBytesToUserFormat(value: number) {
    const units = ['Байт', 'Кб', 'Мб', 'Гб', 'Тб'];

    let l = 0, n = value;

    while (n >= 1024 && ++l) {
        n = n / 1024;
    }

    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}