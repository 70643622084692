import { ArticlesRepository, DoctorsRepository, UsersRepository } from '../api/BackendRepositories';
import { PageContainer } from '../components/Main';
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { NotFound, ErrorPage } from '../pages/BasePages';
import { SizedImage, RedirectBtn, SubmitBtn, TextWithIcon, CustomLink } from '../components/Elements';
import { SubscribeGlobalState } from "../infrastructure/events"
import { getEnumRuValue } from '../infrastructure/utils';

const articlesRepository = new ArticlesRepository();
const usersRepository = new UsersRepository();

export const Article: React.FC = () => {
    const [articleResponse, setArticleResponse] = useState<TResponseWithData<TArticle> | undefined>();
    const { id } = useParams();
    const authorizedUser = SubscribeGlobalState('authorizedUser');
    const enums = SubscribeGlobalState('enums');

    useEffect(() => {
        if (id) {
            const parsedId = parseInt(id);

            if (!isNaN(parsedId)) {
                articlesRepository.getArticle(parsedId)
                    .then(v => setArticleResponse(v));
            }
        }
    }, [id]);

    if (!id || isNaN(parseInt(id))) {
        return <NotFound />
    }

    return <PageContainer
        child={
            <div>
                {articleResponse
                    ? articleResponse.hasErrors
                        ? <ErrorPage errors={articleResponse.errors} withBase={false} />
                        : <ArticleContainer article={articleResponse?.data!} authorizedUser={authorizedUser} enums={enums} />
                    : <div></div>}
            </div>
        }
    />
}

export const ArticleContainer: React.FC<{
    article: TArticle
    authorizedUser: TAuthorizedUser
    enums?: TEnums
}> = props => {
    const article = props.article;
    const enums = props.enums;
    const authorizedUser = props.authorizedUser;
    const isSameUser = authorizedUser && authorizedUser?.id == article.doctor.id;

    const specializations = enums ? enums['ArticleSpecializationType'] : [];

    const [hasFavorite, setHasFavorite] = useState<boolean>(article.hasFavorite);
    const [hasFollow, setHasFollow] = useState<boolean>(article.doctor.hasFollow);

    function updateHasFavorite() {
        articlesRepository.updateFavorite(article.id)
            .then(t => setHasFavorite(t.hasErrors ? hasFavorite : !hasFavorite));
    }

    function updateHasFollow() {
        usersRepository.updateHasFollow(article.doctor.id)
            .then(t => setHasFollow(t.hasErrors ? hasFollow : !hasFollow));
    }

    return <div>
        <div className='flex flex-row justify-between'>
            <div className='flex items-center'>
                <CustomLink href={`/users/${article.doctor.id}`}
                    child={
                        <div className='flex flex-row items-center'>
                            {
                                article.doctor.photoPath
                                    ? <SizedImage className='w-[80px] h-[80px] mr-5'
                                        src={article.doctor.photoPath}
                                        imgClassName='rounded-full object-cover object-top'
                                        fromCdn={true}
                                    />
                                    : <></>
                            }
                            <div>
                                <div className='text-[18px]'>
                                    <span className='text-bold'>{article.doctor.name} </span>
                                    <span className='text-bold'>{article.doctor.lastname}</span>
                                </div>
                                <div className='text-[16px] text-regular'>
                                    {article.doctor.direction}
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
            {
                authorizedUser
                    ? isSameUser
                        ? <div className='flex items-center'>
                            <RedirectBtn
                                colorType={`${hasFollow ? 'default' : 'dark'}`}
                                href={`/articles/${article.id}/edit`}
                                textElement={'Изменить'}
                            />
                        </div>
                        : <div className='flex items-center'>
                            <SubmitBtn colorType={`${hasFollow ? 'default' : 'dark'}`}
                                onClick={() => updateHasFollow()}
                                textElement={
                                    <TextWithIcon
                                        className=''
                                        text={hasFollow ? 'Отписаться' : 'Подписаться'}
                                        src={hasFollow ? `/images/icons/followed.png` : '/images/icons/follow.png'} />}
                            />
                            <SubmitBtn
                                onClick={() => updateHasFavorite()}
                                colorType={`${hasFavorite ? 'default' : 'dark'}`}
                                className='ml-2 h-[48px]'
                                textElement={
                                    <TextWithIcon
                                        src={hasFavorite ? `/images/icons/favorited.png` : '/images/icons/favorite.png'} />}
                            />
                        </div>
                    : <></>



            }
        </div>
        <div className='mt-[45px]'>
            <div className='tracking-widest text-[16px] uppercase text-sbold'>
                {getEnumRuValue(specializations, article.specialization)}
            </div>
            <div className='text-[36px] text-sbold mt-5'>
                {article.title}
            </div>
            {
                article.imagePath
                    ? <SizedImage
                        className='mt-10 w-[100%]'
                        fromCdn={true}
                        src={article.imagePath}
                    />
                    : <></>
            }
            <div className='text-[20px] my-10'>
                {article.content.split('\n').map(m =>
                    <div key={m}>
                        <label className='text-light'>
                            {m}
                            <br />
                        </label>
                    </div>)
                }
            </div>
        </div>
    </div>
}

