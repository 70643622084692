import { useSearchParams, SetURLSearchParams } from "react-router-dom"
import React from "react"

interface IStates {
    authorizedUser: TAuthorizedUser
    enums: TEnums
}

type TStateName = keyof IStates

interface IStateSubscribe<T extends TStateName = TStateName> {
    stateName: T
    callback: (data: IStates[T] | undefined) => void
}

const stateValues: { [K in TStateName]?: IStates[K] } = {}
const stateSubscribes: IStateSubscribe<any>[] = []

export function getGlobalstate<N extends TStateName>(name: N): IStates[N] | undefined {
    return stateValues[name]
}

export function setGlobalState<N extends TStateName>(name: N, data: IStates[N]) {
    stateValues[name] = data

    for (const sub of stateSubscribes) {
        if (sub.stateName === name) {
            sub.callback(data)
        }
    }
}

export function SubscribeGlobalState<N extends TStateName>(name: N): IStates[N] | undefined {
    const [data, setData] = React.useState<IStates[N] | undefined>(stateValues[name])
    React.useEffect(() => subscribeGlobalStateHadler(name, d => {
        setData(d)
    }), [name])

    return data
}

function subscribeGlobalStateHadler<N extends keyof IStates>(name: N,
    callback: (data: IStates[N] | undefined) => void): () => void {
    const sub: IStateSubscribe<N> = {
        stateName: name,
        callback
    }
    stateSubscribes.push(sub)

    callback(stateValues[name])

    return () => stateSubscribes.splice(stateSubscribes.indexOf(sub), 1)
}



export function setQueryParam(key: string, value: string, useSearchParams: [URLSearchParams, SetURLSearchParams]) {
    const [searchParams, setSearchParams] = useSearchParams;

    clearQueryParams(searchParams);

    searchParams.set(key, value);
    setSearchParams();
}

export function clearQueryParams(searchParams: URLSearchParams) {
    for (const paramKey in searchParams.keys()) {
        searchParams.delete(paramKey);
    }
}


export function wait(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function SubscribeClickOutside(ref: React.MutableRefObject<HTMLElement | null>, func: () => void) {
	React.useEffect(
		() => {
			const listener = (event: TouchEvent | MouseEvent) => {
				if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
					return
				}

				func()
			}

			document.addEventListener("mousedown", listener)
			document.addEventListener("touchstart", listener)

			return () => {
				document.removeEventListener("mousedown", listener)
				document.removeEventListener("touchstart", listener)
			}
		},
		[ref, func]
	)
}