import { PageContainer } from '../components/Main';
import { SizedImage, RedirectBtn, TextLikeBtn, TextWithIcon, CustomLink, SubmitBtn } from '../components/Elements';
import { NotFound, ErrorPage } from '../pages/BasePages';
import { useState, useEffect } from "react"
import { getEnumRuValue, isNothing, toDate } from "../infrastructure/utils"
import { UsersRepository, ArticlesRepository } from "../api/BackendRepositories";
import { SubscribeGlobalState } from "../infrastructure/events"
import { useParams, useSearchParams } from "react-router-dom"

const usersRepository = new UsersRepository();
const articlesRepository = new ArticlesRepository();

export const User: React.FC = () => {
    const [userResponse, setUserResponse] = useState<TResponseWithData<TUser> | undefined>();
    const authorizedUser = SubscribeGlobalState('authorizedUser');
    const enums = SubscribeGlobalState('enums');
    const specializations = enums ? enums['DoctorSpecializationType'] : [];

    const { id } = useParams();

    useEffect(() => {
        if (id)
            usersRepository.getUser(id)
                .then(v => setUserResponse(v));
    }, [id]);

    if (!id) {
        return <NotFound />
    }

    return <PageContainer
        w='w-[55%]'
        child={
            <div>
                {userResponse
                    ? userResponse.hasErrors
                        ? <ErrorPage errors={userResponse.errors} withBase={false} />
                        : <UserContainer user={userResponse?.data!} authorizedUser={authorizedUser} enums={specializations} />
                    : <div></div>}
            </div>
        }
    />
}

const UserContainer: React.FC<{
    user: TUser
    authorizedUser: TAuthorizedUser
    enums: TKeyValuePair<string, string>[]
}> = props => {
    const user = props.user;
    const authorizedUser = props.authorizedUser;
    const showSubBlock = !isNothing(authorizedUser) && user.id != authorizedUser?.id;
    const showConsultationBlock = !isNothing(authorizedUser) && user.id != authorizedUser?.id;

    const [hasFollow, setHasFollow] = useState<boolean>(user.hasFollow);
    const [searchParams, setSearchParams] = useSearchParams()

    function updateHasFollow() {
        usersRepository.updateHasFollow(user.id)
            .then(t => setHasFollow(t.hasErrors ? hasFollow : !hasFollow));
    }

    return <div>
        <div className='flex flex-row'>
            <div className='mr-10'>
                {
                    user.photoPath
                        ? <SizedImage
                            src={user.photoPath}
                            className='w-[300px] h-[300px]'
                            imgClassName='rounded-full object-cover object-top'
                            fromCdn={true}
                        />
                        : <></>
                }
            </div>
            <div className=''>
                <div className='text-36'>
                    <span className='text-sbold'>{user.lastname}</span>
                    <span className='text-sbold'> {user.name}</span>
                    {user.surname ? <span className='text-sbold'> {user.surname}</span> : <></>}
                </div>
                <div className='text-24 text-regular mt-2 flex'>
                    {user.isDoctor
                        ? user.doctor?.direction
                            ? `${getEnumRuValue(props.enums, user.doctor?.specialization || '')} • ${user.doctor?.direction}`
                            : getEnumRuValue(props.enums, user.doctor?.specialization || '')
                        : 'Пациент'
                    }
                </div>
                {user.description ? <div className='text-18 text-regular mt-8 flex'>
                    {user.description}
                </div> : <></>}
                {
                    user.doctor
                        ? <div>
                            {
                                user.doctor.certificateNumber 
                                    ? <div>Номер сертификата: {user.doctor.certificateNumber}</div>
                                    : <></>
                            }
                            <div className='mt-[60px] flex'>
                                {
                                    showSubBlock
                                        ? <SubmitBtn
                                            colorType={`${hasFollow ? 'default' : 'dark'}`}
                                            onClick={() => updateHasFollow()}
                                            textElement={
                                                <TextWithIcon
                                                    text={hasFollow ? 'Отписаться' : 'Подписаться'}
                                                    src={hasFollow ? `/images/icons/followed.png` : '/images/icons/follow.png'} />}
                                        />
                                        : <></>
                                }
                                {
                                    showConsultationBlock
                                        ? <SubmitBtn
                                            className='ml-2'
                                            colorType='dark'
                                            textElement={'Задать вопрос'}
                                            onClick={() => {
                                                searchParams.set("popup", "new_consultation")
                                                searchParams.set("doctorId", user.id.toString())
                                                setSearchParams(searchParams)
                                            }}
                                        />
                                        : <></>
                                }
                                <TextLikeBtn className='ml-2' colorType='gray'
                                    textElement={
                                        <TextWithIcon
                                            className=''
                                            text={`${user.doctor?.articlesCount} Статьи`}
                                            src={`/images/icons/articles.webp`} />}
                                />
                                <TextLikeBtn className='ml-2' colorType='gray'
                                    textElement={
                                        <TextWithIcon
                                            className=''
                                            text={`${user.followersCount} Подписчика`}
                                            src={`/images/icons/followers.webp`} />}
                                />
                            </div>
                        </div>
                        : <></>
                }
            </div>
        </div>
        <hr className='my-10' />
        {user.doctor ? <DoctorBlocks user={user} /> : <></>}
    </div>
}

const DoctorBlocks: React.FC<{ user: TUser }> = props => {
    const user = props.user;

    const [articles, setArticles] = useState<TArticlePreview[] | undefined>();
    useEffect(() => {
        articlesRepository.getUserPreviews(user.id)
            .then((t) => { setArticles(t); });
    }, [user.id])

    return <div>
        {articles && articles.length > 0
            ? <div>
                <div className='text-[38px]  flex flex-row justify-between'>
                    <div className='text-sbold'>Статьи</div>
                    <div>
                        <RedirectBtn href={`/users/${user.id}/articles`}
                            colorType={'dark'}
                            textElement={'Все статьи'} />
                    </div>
                </div>
                <div className='mt-6 grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-4'>
                    {articles.map(m => <ArticleCard articePreview={m} key={m.id} />)}
                </div>
            </div>
            : <div></div>
        }
    </div>
}

const ArticleCard: React.FC<{ articePreview: TArticlePreview }> = props => {
    const artice = props.articePreview;

    return <CustomLink
        href={`/articles/${artice.id}`}
        child={
            <div>
                {
                    <SizedImage
                        imgWitoutFull={true}
                        imgClassName='object-cover h-[150px] w-[230px] object-top'
                        templateIfSrcEmpty='/images/article_placeholder.png'
                        src={artice.imagePath}
                        fromCdn={true}
                    />
                }
                <div className='mx-auto w-[90%] mt-5'>
                    <div className='text-[10px] text-gray'>{toDate(artice.createdAt)}</div>
                    <div className='text-[14px] text-bold'>{artice.title}</div>
                </div>
            </div>
        }
    />
}