import { PageContainer } from '../components/Main';
import { SelectionBtn, SizedImage, CustomLink } from '../components/Elements';
import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { SubscribeGlobalState } from "../infrastructure/events"
import { DoctorsRepository } from "../api/BackendRepositories";

const doctorsRepository = new DoctorsRepository();

export const Doctors: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const enums = SubscribeGlobalState('enums');
    const specializations = enums ? enums['DoctorSpecializationType'] : [];

    const selectedSpecializationFilter = searchParams.get('specialization') || 'All';
    const [doctors, setDoctors] = useState([] as TDoctorListModel[]);

    useEffect(() => {
        doctorsRepository.getDoctors(0, selectedSpecializationFilter)
            .then(v => setDoctors(v.data.values));
    }, [selectedSpecializationFilter])

    function updateDoctorTagFilter(specialization: string) {
        searchParams.set('specialization', specialization);
        setSearchParams(searchParams);
    }

    return <PageContainer
        child={
            <div>
                <div className='flex items-end justify-between'>
                    <div className='flex text-36 text-bold'>
                        Врачи
                    </div>
                    {
                        /*
                    <div className='flex text-18'>
                        Детский врач
                    </div>
                        */
                    }
                </div>
                <div className='mt-10 flex gap-2 flex-wrap'>
                    {
                        specializations.map(m => <SelectionBtn
                            key={m.value} text={m.value} value={m.key} selectedItem={selectedSpecializationFilter}
                            onSelect={(tag) => updateDoctorTagFilter(tag)} />)
                    }
                </div>
                <div className='grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-2 gap-8 mt-8'>
                    {doctors.length !== 0 ? doctors.map(v => <DoctorCard key={v.id} doctor={v} />) : <div> Врачи не найдены </div>}
                </div>
            </div>
        }
    />
}

const DoctorCard: React.FC<{ doctor: TDoctorListModel }> = props => {
    const doctor = props.doctor;

    return <div>
        <CustomLink
            href={`/users/${doctor.id}`}
            child={<div>
                <SizedImage
                    className='h-[150px] relative'
                    imgClassName='object-cover h-[150px] w-full object-top'
                    templateIfSrcEmpty='/images/article_placeholder.png'
                    src={doctor.photoPath}
                    fromCdn={true}
                />
                <p className='text-14 font-bold mt-2'>
                    {`${doctor.lastname} ${doctor.name} ${doctor.surname || ''}`}
                </p>
                <p className='text-12'>
                    {doctor.direction}
                </p>
                <p className='text-12 mt-2'>
                    {doctor.workPlace}
                </p>
            </div>}
        />
    </div>
}