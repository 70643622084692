import { useState } from "react"
import { AuthRepository } from '../api/BackendRepositories';
import { SizedImage, MultiOptionsBtn, ApiRequestForm } from "../components/Elements"
import { AuthService } from "../infrastructure/services";

const authRepository = new AuthRepository();
const authService = new AuthService();

export const Auth: React.FC = () => {
    const [authType, setAuthType] = useState('login' as TAuthPageType)

    return <div className="ml-[23%] mt-10">
        <SizedImage
            className="h-[40px]"
            src="/images/logo_auth_page.webp"
        />
        <div className="mt-10 flex gap-4">
            <div>
                <p className="text-30 text-bold">
                    Добро пожаловать!
                </p>
                <p className="text-20 mt-3">
                    Мы ждали вас, чтобы помочь вам позаботиться о себе
                </p>
                <MultiOptionsBtn
                    className="mt-8"
                    activeValue={authType}
                    values={[{ text: "Зарегистрироваться", value: 'signup' }, { text: 'Войти', value: 'login' }]}
                    onClick={(val) => setAuthType(val as TAuthPageType)}
                />
                <div className="mt-10 border-shadow w-[500px]">
                    {authType === 'login'
                        ? <Login />
                        : <Signup
                            onRegisterOk={() => setAuthType('login')}
                        />
                    }
                </div>
            </div>
            <div>
                <SizedImage
                    className=""
                    src="/images/auth_doctor.webp"
                />
            </div>
        </div>

    </div>
}

const Login: React.FC = () => {
    return ApiRequestForm<TLoginRequest, TResponseWithData<TLogin>>(
        (request) => authRepository.login(request),
        (request, response) => {
            authService.add(response.data!);
            window.location.href = '/feed';
        },
        [{
            type: 'email',
            propName: 'Email',
            placeholder: 'Email',
            isRequired: true
        },
        {
            type: 'password',
            propName: 'Password',
            className: 'mt-4',
            isRequired: true,
            placeholder: 'Пароль'
        }],
        undefined,
        <p className="text-12 text-gray ml-6 mt-2 mx-auto">
            Авторизуясь, вы соглашаетесь с <span className="text-red"> Пользовательским соглашением</span>
        </p>
    );
}

const Signup: React.FC<{
    onRegisterOk: any
}> = props => {
    return ApiRequestForm<TRegistraionRequest, TResponseWithData<TLogin>>(
        (request) => authRepository.register(request),
        (request, response) => {
            if (!response.hasErrors) {
                authService.add(response.data!);
                window.location.href = '/feed';
            }
        },
        [{
            propName: 'FirstName',
            placeholder: 'Имя',
            isRequired: true
        },
        {
            className: 'mt-4',
            propName: 'LastName',
            placeholder: 'Фамилия',
            isRequired: true
        },
        {
            className: 'mt-4',
            propName: 'surname',
            placeholder: 'Отчество'
        },
        {
            className: 'mt-4',
            type: 'email',
            propName: 'Email',
            placeholder: 'Email',
            isRequired: true
        },
        {
            className: 'mt-4',
            type: 'password',
            propName: 'Password',
            placeholder: 'Пароль',
            isRequired: true
        },
        {
            className: 'mt-4 text-[20px]',
            type: 'checkbox',
            propName: 'IsDoctor',
            placeholder: 'Я врач'
        }],
        undefined,
        <p className="text-12 text-gray ml-6 mt-2 mx-auto">
            Регистрируясь, вы соглашаетесь с <span className="text-red">Пользовательским соглашением </span>
            и <span className="text-red"> Политикой конфиденциальности</span>
        </p>
    );
}