import { ArticlesRepository, ConsultationsRepository, UsersRepository } from '../api/BackendRepositories';
import { PageContainer } from '../components/Main';
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { NotFound, ErrorPage } from './BasePages';
import { SizedImage, RedirectBtn, SubmitBtn, TextWithIcon, CustomLink } from '../components/Elements';
import { SubscribeGlobalState } from "../infrastructure/events"
import { isNothing, toDate, toDateWithTime } from '../infrastructure/utils';
import TextareaAutosize from 'react-textarea-autosize';

const consultationsRepository = new ConsultationsRepository();

export const Consultation: React.FC = () => {
    const [consultationResponse, setConsultationResponse] = useState<TResponseWithData<TConsultation> | undefined>();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            const parsedId = parseInt(id);

            if (!isNaN(parsedId)) {
                consultationsRepository.get(parsedId)
                    .then(v => setConsultationResponse(v));
            }
        }
    }, [id]);

    if (!id || isNaN(parseInt(id))) {
        return <NotFound />
    }


    return <PageContainer
        checkAuth={true}
        w='w-[50%]'
        child={
            <div>
                {consultationResponse
                    ? consultationResponse.hasErrors
                        ? <ErrorPage errors={consultationResponse.errors} withBase={false} />
                        : <ConsultationContainer consultation={consultationResponse?.data!} />
                    : <div></div>}
            </div>
        }
    />
}

export const ConsultationContainer: React.FC<{
    consultation: TConsultation
}> = props => {
    const consultation = props.consultation;
    const authorizedUser = SubscribeGlobalState('authorizedUser');

    return <div>
        <div className='mt-10'>
            <div className='text-[24px] text-sbold mt-5 flex flex-row justify-between'>
                <div>{consultation.title}</div>
                <div className='text-[16px] text-gray'>
                    {`${toDateWithTime(consultation.createdAt)}`}
                </div>
            </div>
        </div>
        <div className='mt-5 '>
            <div>
                <div className='mt-5 border p-2 rounded text-[16px]'>
                    {consultation.question.split('\n').map(m =>
                        <div key={m}>
                            <label className=''>
                                {m}
                                <br />
                            </label>
                        </div>)
                    }
                </div>
            </div>
            {
                consultation.answer
                    ? <AnsweredBlock consultation={consultation} />
                    : authorizedUser?.id == consultation.doctor.id
                        ? <AnswerBlock consultation={consultation} />
                        : <></>
            }
        </div>
    </div>
}

export const AnsweredBlock: React.FC<{
    consultation: TConsultation
}> = props => {
    const consultation = props.consultation;

    return <div className='mt-10'>
        <div>
            <div className='flex flex-row justify-between items-center'>
                <div className='flex items-center'>
                    <CustomLink
                        href={`/users/${consultation.doctor.id}`}
                        child={
                            <div className='flex flex-row items-center'>
                                <div>
                                    <div className='text-[18px]'>
                                        <span className='text-bold'>{consultation.doctor.name} </span>
                                        <span className='text-bold'>{consultation.doctor.lastname}</span>
                                    </div>
                                    <div className='text-[16px] text-regular'>
                                        {consultation.doctor.direction}
                                    </div>
                                </div>
                                {
                                    consultation.doctor.photoPath
                                        ? <SizedImage className='w-[60px] h-[60px] ml-5'
                                            src={consultation.doctor.photoPath}
                                            imgClassName='rounded-full object-cover object-top'
                                            fromCdn={true}
                                        />
                                        : <></>
                                }
                            </div>
                        }
                    />
                </div>
                <div className='text-[16px] text-gray'>
                    {`${toDateWithTime(consultation.answerAt!)}`}
                </div>
            </div>
        </div>
        <div className='mt-5 border p-2 rounded text-[16px]'>
            {consultation.answer!.split('\n').map(m =>
                <div key={m}>
                    <label className=''>
                        {m}
                        <br />
                    </label>
                </div>)
            }
        </div>
    </div>
}

export const AnswerBlock: React.FC<{
    consultation: TConsultation
}> = props => {
    const consultation = props.consultation;
    const [consultationAnswer, setConsultationAnswer] = useState({
        id: consultation.id
    } as TConsultationAnswer);
    const [isValid, setIsValid] = useState(false);

    return <div className='mt-10'>
        <div className='text-[16px]'>
            <TextareaAutosize
                placeholder='Текст статьи (максимум 3000 символов)'
                minRows={6}
                maxLength={3000}
                className='input-text sbold'
                onChange={(v) => {
                    updateAnswer(v.target.value);
                }}
            />
        </div>
        <SubmitBtn
            className='mt-5'
            colorType={`${isValid ? 'dark' : 'default'}`}
            isDisabled={!isValid}
            textElement={'Отправить ответ'}
            onClick={() => {
                consultationsRepository.updateAnswer(consultationAnswer)
                    .then(r => {
                        if (r.hasErrors) {
                            alert(r.errors[0])
                            return;
                        }

                        window.location.reload();
                    });
            }}
        />
    </div>

    function updateAnswer(answer: string) {
        setConsultationAnswer({
            answer: answer,
            id: consultation.id
        });

        setIsValid(!isNothing(answer));
    }
}