import { AuthService } from "../infrastructure/services";

export class ApiClient {
    _url: string;
    _authService: AuthService;

    constructor(public url: string) {
        this._url = url;
        this._authService = new AuthService();
    }

    async getEmpty(path: string, params?: Record<string, string>): Promise<TResponse> {
        const url = this.getUrl(path, params);
        const headers = this.getDefaultHeaders('GET');

        const res = await fetch(url, {
            method: 'GET',
            headers: headers
        });

        const text = await res.text()
        return JSON.parse(text);
    }

    async get<T>(path: string, params?: Record<string, string>): Promise<TResponseWithData<T>> {
        const url = this.getUrl(path, params);
        const headers = this.getDefaultHeaders('GET');

        const res = await fetch(url, {
            method: 'GET',
            headers: headers
        });

        const text = await res.text()
        return JSON.parse(text);
    }

    async getWithPagination<T>(path: string, page: number, params?: Record<string, string>, count?: number)
        : Promise<TResponseWithPaginationData<T>> {

        if (params) {
            params['page'] = page.toString();
        } else {
            params = {
                page: page.toString()
            }
        }

        if (count) {
            params['count'] = count.toString();
        }

        const url = this.getUrl(path, params);
        const headers = this.getDefaultHeaders('GET');

        const res = await fetch(url, {
            method: 'GET',
            headers: headers
        });

        const text = await res.text()
        return JSON.parse(text);
    }

    async getBlob(path: string, params?: Record<string, string>): Promise<Blob> {
        const url = this.getUrl(path, params);
        const headers = this.getDefaultHeaders('GET');

        const res = await fetch(url, {
            method: 'GET',
            headers: headers
        });

        return await res.blob();
    }

    async delete(path: string, params?: Record<string, string>): Promise<TResponse> {
        const url = this.getUrl(path, params);
        const headers = this.getDefaultHeaders('DELETE');

        const res = await fetch(url, {
            method: 'DELETE',
            headers: headers
        });

        const text = await res.text()
        return JSON.parse(text);
    }

    async postOrPut<TRequest, TResponse>(path: string, model: TRequest, method: 'POST' | 'PUT')
        : Promise<TResponseWithData<TResponse>> {
        const url = this.getUrl(path);
        const headers = this.getDefaultHeaders(method);

        const res = await fetch(url, {
            method: method,
            headers: headers,
            body: JSON.stringify(model)
        });

        const text = await res.text()
        return JSON.parse(text);
    }

    async postOrPutEmpty<TRequest>(path: string, model: TRequest, method: 'POST' | 'PUT')
        : Promise<TResponse> {
        const url = this.getUrl(path);
        const headers = this.getDefaultHeaders(method);

        const res = await fetch(url, {
            method: method,
            headers: headers,
            body: JSON.stringify(model)
        });

        const text = await res.text();
        return JSON.parse(text);
    }

    async postFormData(path: string, fields: TKeyValuePair<string, any>[], requestMethod?: 'POST' | 'PUT')
        : Promise<TResponse> {
        const method = requestMethod || 'POST';
        const url = this.getUrl(path);
        const headers = this.getDefaultHeaders(method, 'formData');

        const formData = new FormData();
        fields?.forEach(f => formData.append(f.key, f.value));

        const res = await fetch(url, {
            method: method,
            headers: headers,
            body: formData
        });

        const text = await res.text();
        return JSON.parse(text);
    }

    private getUrl(path: string, params?: Record<string, string>): string {
        let url = `${this._url}/${path}`;

        if (params) {
            url += '?' + new URLSearchParams(params)
        }

        return url;
    }

    private getDefaultHeaders(method: 'GET' | 'DELETE' | 'POST' | 'PUT', contentType?: 'json' | 'formData'): HeadersInit {
        const cookie = window.document.cookie;
        const authToken = this._authService.getToken();
        contentType = contentType ?? 'json';

        if (method == 'GET' || method == 'DELETE')
            return {
                'Access-Control-Allow-Origin': 'https://doqudo.ru/',
                mode: 'no-cors',
                'Cookie': cookie,
                'Authorization': 'Bearer ' + authToken,
            };

        if (contentType == 'json')
            return {
                'Cookie': cookie,
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authToken,
            }

        return {
            'Cookie': cookie,
            'Accept': '*/*',
            'Authorization': 'Bearer ' + authToken,
        }
    };
} 