import { getGlobalstate, setGlobalState } from "./events"
import { isNothing } from "./utils";
import { jwtDecode } from 'jwt-decode'
import { UtilsRepository } from "../api/BackendRepositories";

const lsAuthTokenName = 'authToken';

export class UtilsService{
    _utilsRepository = new UtilsRepository();

    async initEnums() {
        const response = await this._utilsRepository.getAllEnums();
        setGlobalState('enums', response.data || {});
    }
}

export class AuthService {
    initAuth() {
        const token = localStorage.getItem(lsAuthTokenName);

        if (isNothing(token)) {
            return;
        }

        this.setAuthState(token!);
    }

    getToken(): string | null {
        return localStorage.getItem(lsAuthTokenName);
    }

    add(login: TLogin) {
        localStorage.setItem(lsAuthTokenName, login.token);
        this.setAuthState(login.token);
    }

    remove() {
        localStorage.removeItem(lsAuthTokenName);
        this.setAuthState(undefined);
    }

    private setAuthState(token?: string) {
        if (!token) {
            setGlobalState("authorizedUser", undefined)
            return;
        }

        const decodedJwt = jwtDecode<TJwtToken>(token);
        const user = {
            id: parseInt(decodedJwt.nameid),
            email: decodedJwt.email,
            role: decodedJwt.role,
            shortName: decodedJwt.shortName,
        } as TAuthorizedUser

        setGlobalState("authorizedUser", user)
    }
}