import { useEffect, useState } from "react"
import React from "react"
import { isNothing } from "../infrastructure/utils"
import Dropzone, { Accept } from 'react-dropzone'
import { FileRejection } from 'react-dropzone'
import { CdnUrl } from '../api/BackendRepositories'
import { ArticlesRepository, CdnFilesRepository, PrivateFilesRepository } from '../api/BackendRepositories';

const cdnFilesRepository = new CdnFilesRepository();

export const SizedImage: React.FC<{
	src?: string,
	templateIfSrcEmpty?: string,
	className?: string,
	templateClassName?: string,
	imgClassName?: string,
	imgWitoutFull?: boolean,
	alt?: string,
	fromCdn?: boolean
}> = props => {

	const src = props.src
		? props.fromCdn
			? `${CdnUrl}?path=${props.src}`
			: props.src
		: props.templateIfSrcEmpty;

	return <div className={props.className || ""}>
		<img
			className={`${props.imgClassName || " "} ${props.imgWitoutFull ? ' ' : ' h-full '}`}
			src={src}
			alt={props.alt || ""}
		/>
	</div>
}

export const TextWithIcon: React.FC<{
	src: string,
	className?: string,
	imgClassName?: string,
	textClassName?: string,
	text?: string
}> = props =>
		<div className={`${props.className || "flex flex-row"} `}>
			<div className="">
				<img className={`h-[20px] inline align-middle ${props.imgClassName || ""}`} alt='' src={props.src} />
			</div>
			{
				props.text
					? <div className={`ml-2 ${props.textClassName || " align-baseline"} `}>
						{props.text}
					</div>
					: <></>
			}
		</div>

export const CustomLink: React.FC<{
	href: string,
	child: any
	className?: string,
	hrefClassName?: string,
}> = props =>
		<div className={props.className || ""}>
			<a className={`h-full ${props.hrefClassName || ""}`} href={props.href}>
				{props.child}
			</a>
		</div>

export const SelectionBtn: React.FC<{
	text: string
	value: any
	className?: string
	onSelect: (item: string) => any,
	selectedItem: string
}> = props => {
	const isActive = props.selectedItem == props.value;

	return <div className={`${isActive ? "selection-btn-active" : "selection-btn"}  ${props.className || ''}`}
		onClick={() => props.onSelect(props.value)}
	>
		{props.text}
	</div>
}

export const RedirectBtn: React.FC<{
	textElement: any
	colorType?: TBtnColorType
	sizeType?: TBtnSizeType
	href: string
	className?: string
}> = props => {
	const btnClass = getBtnClass(props.colorType, props.sizeType);

	return <a className={`flex ${btnClass} ${props.className || ' '}`}
		href={props.href}>
		{props.textElement}
	</a>
}

export const TextLikeBtn: React.FC<{
	textElement: any
	colorType?: TBtnColorType
	sizeType?: TBtnSizeType
	className?: string
}> = props => {
	const btnClass = getBtnClass(props.colorType, props.sizeType);

	//@ts-ignore
	return <div disabled className={`${props.className || ' '} ${btnClass}`}>
		{props.textElement}
	</div>
}

export const MultiOptionsBtn: React.FC<{
	values: { text: string, value: string }[]
	activeValue: string
	className?: string
	onClick: (item: string) => any
}> = props => {
	const values = props.values;
	const activeValue = props.activeValue;

	return <div className={`multi-options ${props.className || ''}`}>
		{values.map(v =>
			<div className={`multi-options-value${activeValue == v.value ? '-active' : ''}`}
				key={v.value}
				onClick={() => props.onClick(v.value)}>
				{v.text}
			</div>
		)}
	</div >
}

export const SubmitBtn: React.FC<{
	textElement: any
	className?: string
	colorType?: TBtnColorType
	sizeType?: TBtnSizeType
	onClick?: any
	isDisabled?: boolean
}> = props => {
	const btnClass = getBtnClass(props.colorType, props.sizeType);

	return <button className={`${btnClass} ${props.className || ''}`}
		disabled={props.isDisabled || false}
		onClick={props.onClick ? props.onClick : () => { }}
	>
		{props.textElement}
	</button>
}

export const DownloadFileBtn: React.FC<{
	textElement: any
	filePath: string
	fileName: string
	className?: string
	colorType?: TBtnColorType
	sizeType?: TBtnSizeType
	isDisabled?: boolean
}> = props => {
	const btnClass = getBtnClass(props.colorType, props.sizeType);

	return <button className={`${btnClass} ${props.className || ''}`}
		disabled={props.isDisabled || false}
		onClick={() => {
			cdnFilesRepository.getPrivateFile(props.filePath)
				.then(blob => {
					const url = window.URL.createObjectURL(new Blob([blob]));
					const link = document.createElement("a");
					link.href = url;
					link.download = props.fileName;
					document.body.appendChild(link);

					link.click();

					document.body.removeChild(link);
					window.URL.revokeObjectURL(url);
				});
		}}
	>
		{props.textElement}
	</button>
}

export function ApiRequestForm<TReq, TResp,>(
	apiAction: (requestModel: TReq) => Promise<TResp>,
	onSubmit: (requestModel: TReq, responseModel: TResp) => any,
	inputs: {
		className?: string,
		type?: string,
		placeholder: string
		propName: string,
		isRequired?: boolean,
		defaultValue?: string,
		label?: string,
		selectionValues?: TKeyValuePair<string, string>[]
	}[],
	className?: string | undefined | null,
	child?: JSX.Element | undefined,
	btnText?: string,
	btnColorType?: TBtnColorType
) {

	const [btnDisable, setBtnDisable] = useState(true);
	const [model, setModel] = useState({} as any);
	const [errorsModel, setErrorModel] = useState({} as Dictionary<string>);

	useEffect(() => {
		validateForBtnState();
	}, [model])

	useEffect(() => {
		const defaultModel = {} as any;
		inputs.forEach(f => defaultModel[f.propName] = f.defaultValue)
		setModel(defaultModel);
	}, [])

	const requiredProps = inputs
		.filter(f => f.isRequired)
		.map(m => m.propName);

	const inputFileds = inputs.map(v => {
		let input = <></>;

		switch (v.type) {
			case "checkbox":
				input = <div className="flex items-center">
					<input type={"checkbox"} className="h-[20px] w-[20px]"
						onChange={(oc) => updateModel(v.propName, oc.target.checked)}
					/>
					<label className="ml-2">
						{`${v.placeholder}${v.isRequired ? '*' : ''}`}
					</label>
				</div>
				break;
			case "selection":
				const selectionValues = v.selectionValues?.filter(f => f.key != v.defaultValue);
				selectionValues?.push(v.selectionValues?.find(f => f.key == v.defaultValue)!);
				const resultSelectionValues = selectionValues?.reverse();

				console.log(resultSelectionValues)

				input = <select className='input-text'
					onChange={(oc) => updateModel(v.propName, oc.target.value)}
				>
					{resultSelectionValues?.map(m => <option key={m.key} value={m.key}>{m.value}</option>)}
				</select>
				break;
			default: input = <input className={`input-text`}
				type={v.type || 'text'}
				placeholder={`${v.placeholder}${v.isRequired ? '*' : ''}`}
				onChange={(oc) => updateModel(v.propName, oc.target.value)}
				defaultValue={v.defaultValue}
			/>
		}

		return <div key={v.propName} className={`${v.className || ''}`}>
			{
				v.label
					? <label className="text-[16px] text-gray">
						{v.label}
					</label>
					: <></>
			}
			{input}
			<span className="text-red text-center text-[14px]">
				{errorsModel[v.propName]}
			</span>
		</div>
	})

	return <div>
		<div className={className || ' '}>
			{inputFileds}
		</div>
		{child}
		<SubmitBtn className='mt-4'
			colorType={btnColorType || 'default'}
			textElement={`${btnText || "Отправить"}`}
			onClick={async () => {
				const response = await apiAction(model);
				const responseModel = response as TResponse;
				setErrorModel({});

				if (responseModel.hasErrors) {
					const newErrorModel = {} as Dictionary<string>;

					responseModel.errors.forEach(error => {
						if (error.fieldName) {
							newErrorModel[error.fieldName!] = error.text || ''
						}
					});

					setErrorModel(newErrorModel);
				} else {
					onSubmit(model, response);
				}
			}}
			isDisabled={btnDisable}
		/>
	</div>

	function updateModel(propName: any, value: any) {
		console.log(`${propName} ${value}`);
		model[propName] = value;
		setModel(model);
		validateForBtnState();
	}

	function validateForBtnState() {
		const isAllRequiredPropsExists = validateRequiredProps();
		setBtnDisable(!isAllRequiredPropsExists);
	}

	function validateRequiredProps(): boolean {
		let requiredPropsCount = 0;

		requiredProps.forEach(f => {
			if (!isNothing(model[f]))
				requiredPropsCount++;
		})

		return requiredPropsCount == requiredProps.length;
	}
}

export const SearchBlock: React.FC<{
	blockClass?: string
	btnClass?: string
	inputClass?: string
	placeholder?: string
	onClick: (value: string) => any
}> = props => {
	const [value, setValue] = useState("");

	return <div className={`${props.blockClass || ' '} flex items-center flex-row justify-center`}>
		<input
			type="text"
			placeholder={props.placeholder || ''}
			className={`input-search ${props.inputClass || ' '} mr-5 w-[580px] h-[60px] text-light `}
			onChange={(v) => setValue(v.target.value)}
		/>
		<SubmitBtn textElement='Найти'
			className={props.btnClass}
			colorType='dark'
			sizeType='big'
			onClick={() => props.onClick(value)}
		/>
	</div>
}

export const FileDnD: React.FC<{
	className?: string
	maxSizeBytes?: number
	labelText: string
	accept?: Accept
	onDropAccepted: (files: any) => void
	onDropRejected?: (errors: FileRejection[]) => any
}> = props => {
	const [hover, setHover] = useState(false);

	return <Dropzone
		maxFiles={1}
		maxSize={props.maxSizeBytes}
		accept={props.accept}
		onDragEnter={() => { setHover(true); console.log('onDragEnter'); }}
		onDragLeave={() => { setHover(false); console.log('onDragLeave'); }}
		onDropRejected={(fr, de) => {
			if (props.onDropRejected)
				props.onDropRejected(fr);

			setHover(false);
		}}
		onDropAccepted={acceptedFiles => {
			props.onDropAccepted(acceptedFiles);
			setHover(false);
		}}
	>
		{({ getRootProps, getInputProps }) => (
			<section className={`file-dnd${hover ? '-hovered ' : ' '} ${props.className || ' '}`}>
				<div className="w-[100%] h-[100%]" {...getRootProps()}>
					<input {...getInputProps()} />
					<label>
						{props.labelText}
					</label>
				</div>
			</section>
		)}
	</Dropzone>
}

function getBtnClass(colorType?: TBtnColorType, sizeType?: TBtnSizeType) {
	let btnClass = 'btn';

	switch (colorType) {
		case 'dark':
			btnClass = 'btn-dark'
			break;
		case 'white':
			btnClass = 'btn-white'
			break;
		case 'gray':
			btnClass = 'btn-gray'
			break;
		case 'default':
			btnClass = 'btn'
			break;
	}

	switch (sizeType) {
		case 'big':
			btnClass += ' px-[60px] h-[60px] py-[1px] '
			break;
		case 'short':
			btnClass += ' px-6 h-[35px] py-1 '
			break;
		case 'minimal':
			btnClass += ' px-2 h-[35px] py-1 '
			break;
		case 'default':
			btnClass += ' '
			break;
	}

	return btnClass;
}