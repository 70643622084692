import { ArticlesRepository, ConsultationsRepository, UsersRepository } from '../api/BackendRepositories';
import { PageContainer } from '../components/Main';
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { NotFound, ErrorPage } from './BasePages';
import { SizedImage, RedirectBtn, SubmitBtn, MultiOptionsBtn, CustomLink } from '../components/Elements';
import { SubscribeGlobalState } from "../infrastructure/events"
import { getEnumRuValue, toDate, toDateWithTime } from '../infrastructure/utils';

const consultationsRepository = new ConsultationsRepository();

export const Consultations: React.FC = () => {
    const [consultationListResponse, setConsultationListResponse] = useState<TResponseWithData<TConsultationList> | undefined>();

    useEffect(() => {
        consultationsRepository.getList()
            .then(v => setConsultationListResponse(v));
    }, []);

    return <PageContainer
        w='w-[50%]'
        child={
            <div>
                {consultationListResponse
                    ? consultationListResponse.hasErrors
                        ? <ErrorPage errors={consultationListResponse.errors} withBase={false} />
                        : <ConsultationContainer consultationList={consultationListResponse?.data!} />
                    : <div></div>}
            </div>
        }
    />
}

export const ConsultationContainer: React.FC<{
    consultationList: TConsultationList
}> = props => {
    const consultationList = props.consultationList;
    const [consultationPageType, setConsultationPageType] = useState('asking' as TConsultationPageType);

    return <div>
        <MultiOptionsBtn
            className=""
            activeValue={consultationPageType}
            values={[{ text: 'Мои вопросы', value: 'asking' }, { text: "Вопросы мне", value: 'respondent' }]}
            onClick={(val) => setConsultationPageType(val as TConsultationPageType)}
        />
        {
            consultationPageType == 'asking'
                ? <ConsultationListBlocks consultations={consultationList.asking} />
                : <ConsultationListBlocks consultations={consultationList.respondents} />
        }
    </div>
}

export const ConsultationListBlocks: React.FC<{
    consultations: TShortConsultation[]
}> = props => {
    let consultations = props.consultations;

    return consultations.length > 0
        ? <div className='mt-10 grid grid-cols-2 gap-5 mt-10'>
            {consultations.map(v => {
                return <a
                    className='border p-2 rounded-xl'
                    href={`/consultations/${v.id}`}
                >
                    <div>{v.title}</div>
                    <div className='text-[14px] text-gray'>
                        {`Создан: ${toDateWithTime(v.createdAt)}`}
                    </div>
                    <div className='text-[14px] text-gray'>
                        {v.answerAt
                            ? `Ответ дан: ${toDateWithTime(v.answerAt)}`
                            : 'Ожидает ответа'
                        }
                    </div>
                </a>
            })}
        </div>
        : <div className='mt-10'>
            Пусто тут пусто
        </div>
}

