import { ArticlesRepository } from '../api/BackendRepositories';
import { PageContainer } from '../components/Main';
import { useState, useEffect } from "react"
import { SizedImage, RedirectBtn, SelectionBtn, TextWithIcon, CustomLink, SubmitBtn, SearchBlock } from '../components/Elements';
import { toDate } from '../infrastructure/utils';
import { useParams, useSearchParams } from "react-router-dom"

const articlesRepository = new ArticlesRepository();

export const Articles: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    return <PageContainer
        w='w-[50%]'
        child={
            <div>
                <p className='tracking-widest text-[16px] uppercase text-sbold text-center'>
                    СТАТЬИ
                </p>
                <div className='mt-5 text-[36px] text-sbold text-center'>
                    Доказательная медицина от врачей,
                    <p style={{ whiteSpace: 'pre-line' }} className='text-sbold'> которым мы доверяем</p>
                </div>
                <SearchBlock
                    placeholder='Например: Гинекология'
                    onClick={(value) => {
                        searchParams.set("popup", "search_result")
                        searchParams.set("query", value)
                        setSearchParams(searchParams)
                    }}
                    blockClass='mt-[100px] mb-[100px]'
                />
                <hr />
                <RecommendationBlock />
            </div>
        }
    />
}

const RecommendationBlock: React.FC = () => {

    const [recommendedArticles, setRecommendedArticles] = useState<TArticlePreview[] | undefined>();

    useEffect(() => {
        articlesRepository.getRecommended()
            .then(v => setRecommendedArticles(v));
    }, []);

    return <div>
        {recommendedArticles
            ? <div className='mt-10'>
                <div className='text-[32px]  flex flex-row justify-between'>
                    <div className='text-sbold'>Рекомендуем вам</div>
                </div>
                <div className='mt-6 grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-4'>
                    {recommendedArticles.map(m => <ArticleCard articePreview={m} key={m.id} />)}
                </div>
            </div>
            : <div></div>
        }
    </div>
}

const ArticleCard: React.FC<{ articePreview: TArticlePreview }> = props => {
    const artice = props.articePreview;

    return <CustomLink
        href={`/articles/${artice.id}`}
        child={<div>
            {
                <SizedImage
                    className='h-[130px]'
                    imgClassName='object-cover h-[150px] w-[230px] object-top'
                    templateIfSrcEmpty='/images/article_placeholder.png'
                    src={artice.imagePath}
                    fromCdn={true}
                />
            }
            <div className='mx-auto w-[90%] mt-5'>
                <div className='text-[10px] text-gray'>{toDate(artice.createdAt)}</div>
                <div className='text-[14px] text-bold'>{artice.title}</div>
            </div>
        </div>}
    />
}

