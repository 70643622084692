import { ArticlesRepository } from '../api/BackendRepositories';
import { PageContainer } from '../components/Main';
import { useState, useEffect } from "react"
import { SubscribeGlobalState } from "../infrastructure/events"
import { toDate } from "../infrastructure/utils"
import { SizedImage, RedirectBtn, SelectionBtn, TextWithIcon, CustomLink, SubmitBtn, SearchBlock } from '../components/Elements';

const articlesRepository = new ArticlesRepository();

export const Favorite: React.FC = () => {
    return <PageContainer
        checkAuth={true}
        child={
            <div>
                <p className='text-[36px] text-sbold mb-10'>
                    Избранное
                </p>
                <FeedArticlesBlock />
            </div>
        }
    />
}

const FeedArticlesBlock: React.FC = () => {
    const [values, setValues] = useState([] as TFavoriteArticle[]);
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);

    useEffect(() => loadValues(), []);

    function loadValues() {
        articlesRepository.getFavorite(page)
            .then(v => {
                if (v.hasErrors)
                    return;

                const response = v.data;

                setIsLastPage(response.isLastPage);

                if (!response.isLastPage)
                    setPage(page + 1);

                response.values.forEach(f => values.push(f));
                setValues(values);
            });
    }

    return <div>
        {values
            ? <div className='mt-10'>
                <div className='mt-6 grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-2 gap-8'>
                    {
                        values.length > 0
                            ? values.map(m => <ArticleCard article={m} key={m.id} />)
                            : <div>Пусто тут пусто</div>
                    }
                </div>
                {
                    values.length < 1 || isLastPage
                        ? <></>
                        : <SubmitBtn
                            colorType='dark'
                            className='flex mx-auto mt-20'
                            textElement={'Показать еще'}
                            onClick={() => loadValues()}
                        />
                }
            </div>
            : <div></div>
        }
    </div>
}

const ArticleCard: React.FC<{ article: TFavoriteArticle }> = props => {
    const artice = props.article;

    const enums = SubscribeGlobalState('enums');
    const doctorSpecializations = enums ? enums['DoctorSpecializationType'] : [];
    const doctorSpecialization = doctorSpecializations.find(f => f.key == artice.doctor.specialization)?.value;

    return <CustomLink
        href={`/articles/${artice.id}`}
        child={<div>
            {
                <SizedImage
                    imgWitoutFull={true}
                    imgClassName='object-cover h-[150px] w-[230px] object-top'
                    templateIfSrcEmpty='/images/article_placeholder.png'
                    src={artice.imagePath}
                    fromCdn={true}
                />
            }
            <div className='mx-auto w-[90%] mt-5'>
                <div className='text-[14px] text-bold'>{artice.title}</div>
                <div className='text-[10px] mt-4'>
                    {`${artice.doctor.name} ${artice.doctor.lastname} • ${doctorSpecialization}`}
                </div>
            </div>
        </div>}
    />
}

