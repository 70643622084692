import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from "react-router-dom"
import { ArticlesRepository, ConsultationsRepository, PrivateFilesRepository } from '../api/BackendRepositories';
import { SizedImage, RedirectBtn, TextLikeBtn, TextWithIcon, CustomLink, SubmitBtn, FileDnD } from '../components/Elements';
import { getGlobalstate, SubscribeGlobalState, SubscribeClickOutside, setQueryParam } from '../infrastructure/events';
import { convertBytesToUserFormat, isNothing, toDate } from '../infrastructure/utils';
import TextareaAutosize from 'react-textarea-autosize';

const articlesRepository = new ArticlesRepository();
const privateFilesRepository = new PrivateFilesRepository();
const consultationsRepository = new ConsultationsRepository();

export const NewConsultation: React.FC<{
}> = props => {
    const [searchParams, setSearchParams] = useSearchParams()
    const doctorId = searchParams.get("doctorId");
    const [isValid, setIsValid] = useState(false);
    const [consultation, setConsultation] = useState<TNewConsultation>({} as TNewConsultation);

    useEffect(() => {
        consultation.doctorUserId = parseInt(doctorId || '-1');
        setConsultation(consultation);
    }, [])

    return <Popup>
        <div className="">
            <div className='text-[36px] text-sbold'>
                Получить консультацию
            </div>
            <div className='mt-10'>
                <input
                    maxLength={150}
                    className='input-text mt-10 text-[24px] h-[71px] text-sbold'
                    type="text"
                    placeholder='Заголовок'
                    onChange={(v) => {
                        consultation.title = v.target.value;
                        updateArticle(consultation);
                    }}
                />
                <TextareaAutosize
                    placeholder='Текст вопроса (максимум 3000 символов)'
                    minRows={6}
                    maxLength={3000}
                    className='input-text mt-5 text-[20px] sbold'
                    onChange={(v) => {
                        consultation.question = v.target.value;
                        updateArticle(consultation);
                    }}
                />
                <SubmitBtn
                    className='mt-5'
                    colorType={`${isValid ? 'dark' : 'default'}`}
                    isDisabled={!isValid}
                    textElement={'Отправить'}
                    onClick={() => {
                        consultationsRepository.create(consultation)
                            .then(r => {
                                if (r.hasErrors) {
                                    alert(r.errors[0])
                                    return;
                                }

                                window.location.href = (`/consultations/${r.data?.id}`);
                            });
                    }}
                />
            </div>
        </div>
    </Popup>

    function updateArticle(consultation: TNewConsultation) {
        setConsultation(consultation);
        setIsValid(!isNothing(consultation.question) && !isNothing(consultation.title));
    }
}

export const NewFilePopup: React.FC<{
}> = props => {
    const [searchParams, setSearchParams] = useSearchParams()

    const [privateFile, setPrivateFile] = useState<TNewPrivateFile>({
        type: 'Other'
    } as TNewPrivateFile);

    const [fileContent, setFileContent] = useState<File | undefined>();
    const [fileTypeSelectOptions, setFileTypeSelectOptions] = useState([] as JSX.Element[]);
    const [isValid, setIsValid] = useState(false);

    const enums = SubscribeGlobalState('enums');
    const fileTypes = enums ? enums['PrivateFileType'] : [];

    useEffect(() => {
        setFileTypeSelectOptions(fileTypes.filter(f => f.key != 'All')
            .map(m => <option key={m.key} value={m.key}>{m.value}</option>));
    }, []);

    return <Popup>
        <div className="">
            <div className='text-[36px] text-sbold'>
                Добавить файл
            </div>
            <div className='mt-10'>
                <input
                    maxLength={50}
                    className='input-text text-[18px] text-sbold'
                    type="text"
                    placeholder='Название файла'
                    onChange={(v) => {
                        privateFile.fileName = v.target.value;
                        updateArticle(privateFile);
                    }}
                />
                <select className='input-text mt-5 text-[18px] text-sbold'
                    onChange={(v) => {
                        privateFile.type = v.target.value;
                        updateArticle(privateFile);
                    }}
                >
                    {fileTypeSelectOptions}
                </select>
                <FileDnD
                    labelText={fileContent ? 'Загрузить другой файл' : 'Добавить файл'}
                    className='mt-5 w-[600px]'
                    onDropAccepted={(files) => updateArticleImg(files)}
                    onDropRejected={(rejections) => console.log('rejections', rejections)}
                />
                {
                    fileContent
                        ? <div className='text-center mt-2'>
                            <p>{`Загруженный файл: ${fileContent.name}, размер: ${convertBytesToUserFormat(fileContent.size)}`}</p>
                        </div>
                        : <></>
                }
                <SubmitBtn
                    className='mt-5'
                    colorType={`${isValid ? 'dark' : 'default'}`}
                    isDisabled={!isValid}
                    textElement={'Сохранить'}
                    onClick={() => {
                        privateFilesRepository.create(privateFile, fileContent!)
                            .then(r => {
                                if (r.hasErrors) {
                                    alert(r.errors[0].text)
                                    return;
                                }

                                clearSearchParams(searchParams);
                                setSearchParams(searchParams);
                                window.location.reload();
                            });
                    }}
                />
            </div>
        </div>
    </Popup>

    function updateArticle(newPrivateFile: TNewPrivateFile) {
        setPrivateFile(newPrivateFile);
        updateValidation(newPrivateFile);
    }

    function updateValidation(newPrivateFile?: TNewPrivateFile, file?: File) {
        const target = newPrivateFile || privateFile;
        const targetFile = file || fileContent;

        setIsValid(!isNothing(target.fileName) && !isNothing(targetFile));
    }

    function updateArticleImg(files?: any) {
        const file = files ? files[0] : undefined;

        if (!file) {
            setFileContent(undefined);
            return;
        }

        console.log(file);
        setFileContent(file);
        updateValidation(undefined, file);
    }
}

export const SearchArticleResultPopup: React.FC<{
}> = props => {
    const [searchParams, setSearchParams] = useSearchParams()
    const query = searchParams.get("query");

    if (isNothing(query))
        clearSearchParams(searchParams);

    const [values, setValues] = useState([] as TArticlePreview[]);
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);

    useEffect(() => loadValues(), []);

    function loadValues() {
        articlesRepository.searchArticles(query!, page)
            .then(v => {
                if (v.hasErrors)
                    return;

                const response = v.data;

                setIsLastPage(response.isLastPage);

                if (!response.isLastPage)
                    setPage(page + 1);

                response.values.forEach(f => values.push(f));
                setValues(values);
            });
    }

    return <Popup>
        <div className="">
            <div className='text-[18px] text-sbold'>
                Результаты по запросу: {query}
            </div>
            <div className='mt-5'>
                {
                    values && values.length > 0
                        ? <div>
                            <div className='grid grid-cols-3 gap-8'>
                                {
                                    values.map(v => <SearchArticleCard key={v.id} artice={v} />)
                                }
                            </div>
                            {
                                isLastPage
                                    ? <></>
                                    : <div className='mt-10 mx-auto flex flex-row justify-between items-center'>
                                        <SubmitBtn
                                            colorType='dark'
                                            className='flex mx-auto flex flex-row justify-between items-center'
                                            textElement={'Показать еще'}
                                            onClick={() => loadValues()}
                                        />
                                    </div>
                            }
                        </div>

                        : <div>Ничего не найдено</div>
                }
            </div>
        </div>
    </Popup>
}

const SearchArticleCard: React.FC<{ artice: TArticlePreview }> = props => {
    const artice = props.artice;

    return <CustomLink
        className='mt-5'
        href={`/articles/${artice.id}`}
        child={
            <div>
                {
                    artice.imagePath
                        ? <SizedImage
                            imgWitoutFull={true}
                            imgClassName='object-cover h-[150px] w-[230px] object-top'
                            src={artice.imagePath}
                            fromCdn={true}
                        />
                        : <SizedImage className='h-[130px]'
                            imgClassName='object-cover h-[150px] w-[230px] object-center'
                            src={'/images/article_placeholder.png'} />
                }
                <SizedImage src={artice.imagePath} />
                <div className='mx-auto mt-5'>
                    <div className='text-[10px] text-gray'>{toDate(artice.createdAt)}</div>
                    <div className='text-[14px] text-bold'>{artice.title}</div>
                </div>
            </div>
        }
    />
}

const Popup: React.FC<{
    children?: React.ReactNode
    withAuth?: boolean
}> = props => {
    const [searchParams, setSearchParams] = useSearchParams()
    const popupRef = React.useRef<HTMLDivElement>(null)

    SubscribeClickOutside(popupRef, () => {
        const emptySearchParams = clearSearchParams(searchParams);
        setSearchParams(emptySearchParams)
    })

    return (
        <div className="z-[20] h-[100vh] fixed top-0 left-0 right-0 flex bg-black bg-opacity-80 flex flex-col overflow-y-scroll">
            <div ref={popupRef} className="mx-auto mt-[5vh] mb-[20vh] bg-white flex p-[60px]">
                {props.children}
            </div>
            <div className="h-[20vh]" />
        </div>
    )
}

function clearSearchParams(params: URLSearchParams): URLSearchParams {
    params.delete("popup")
    params.delete("query")
    params.delete("doctorId")
    return params;
}