import { ArticlesRepository } from '../api/BackendRepositories';
import { PageContainer } from '../components/Main';
import { NotFound, ErrorPage } from '../pages/BasePages';
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { SubscribeGlobalState } from "../infrastructure/events"
import { toDate } from "../infrastructure/utils"
import { SizedImage, RedirectBtn, SelectionBtn, TextWithIcon, CustomLink, SubmitBtn, SearchBlock } from '../components/Elements';

const articlesRepository = new ArticlesRepository();

export const UserArticles: React.FC = () => {
    return <PageContainer
        child={<UserArticlesBlock />}
    />
}

const UserArticlesBlock: React.FC = () => {
    const [values, setValues] = useState([] as TUserArticle[]);
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        if (id)
            loadValues();
    }, [id]);

    if (!id) {
        return <NotFound />
    }

    function loadValues() {
        articlesRepository.getUserArticles(id!, page)
            .then(v => {
                if (v.hasErrors)
                    return;

                const response = v.data;

                setIsLastPage(response.isLastPage);

                if (!response.isLastPage)
                    setPage(page + 1);

                response.values.forEach(f => values.push(f));
                setValues(values);
            });
    }

    return <div>
        {values && values.length > 0
            ? <div>
                <p className='text-[36px] text-sbold mb-10'>
                    {`Cтатьи от ${values[0].lastname} ${values[0].name}`}
                </p>
                <div className='mt-10'>
                    <div className='grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-2 gap-8 mt-8'>
                        {values.map(m => <ArticleCard article={m} key={m.id} />)}
                    </div>
                    {
                        isLastPage
                            ? <></>
                            : <SubmitBtn
                                colorType='dark'
                                className='flex mx-auto mt-20'
                                textElement={'Показать еще'}
                                onClick={() => loadValues()}
                            />
                    }
                </div>
            </div>
            : <div></div>
        }
    </div>
}

const ArticleCard: React.FC<{ article: TUserArticle }> = props => {
    const artice = props.article;

    return <CustomLink
        href={`/articles/${artice.id}`}
        child={
            <div>
                {
                    <SizedImage
                        imgWitoutFull={true}
                        imgClassName='object-cover h-[150px] w-[230px] object-top'
                        templateIfSrcEmpty='/images/article_placeholder.png'
                        src={artice.imagePath}
                        fromCdn={true}
                    />
                }
                <div className='mx-auto w-[90%] mt-5'>
                    <div className='text-[10px] text-gray'>{toDate(artice.createdAt)}</div>
                    <div className='text-[14px] text-bold'>{artice.title}</div>
                </div>
            </div>
        }
    />
}

