import { ApiClient } from "./ApiClient";
export const CdnUrl = 'https://doqudo.ru/cdn/files';

export abstract class BaseRepository {
    _apiClient: ApiClient;

    constructor(path: string) {
        this._apiClient = new ApiClient(path);
    }
}

export class DoctorsRepository extends BaseRepository {
    constructor() {
        super('/api/v1/doctors');
    }

    async getDoctors(page: number, specialization: string, isChildDirection?: boolean)
        : Promise<TResponseWithPaginationData<TDoctorListModel>> {
        return await this._apiClient.getWithPagination<TDoctorListModel>(
            "list",
            page,
            {
                specialization: specialization,
                isChildDirection: isChildDirection?.toString() || 'false'
            },
            -1);
    }
}

export class UsersRepository extends BaseRepository {
    constructor() {
        super('/api/v1/users');
    }

    async getUser(id: string): Promise<TResponseWithData<TUser>> {
        return await this._apiClient.get<TUser>(id);
    }

    async getProfileUser(): Promise<TResponseWithData<TProfileUser>> {
        return await this._apiClient.get<TProfileUser>("profile");
    }

    async updateHasFollow(id: number): Promise<TResponse> {
        const request = {
            id: id
        } as TUserFollowRequest

        return await this._apiClient.postOrPutEmpty<TUserFollowRequest>("follow", request, 'PUT');
    }

    async updateProfile(model: TUpdateProfile): Promise<TResponse> {
        return await this._apiClient.postOrPutEmpty<TUpdateProfile>("profile", model, "PUT");
    }

    async updatePassword(model: TUpdatePassword): Promise<TResponse> {
        return await this._apiClient.postOrPutEmpty<TUpdatePassword>("password", model, "PUT");
    }

    async updatePhoto(image: File): Promise<TResponseWithData<TNewArticleResponse>> {
        const formFields = [
            { key: 'file', value: image },
        ] as TKeyValuePair<string, string | File>[];

        return await this._apiClient.postFormData("photo", formFields, 'PUT');
    }
}

export class ConsultationsRepository extends BaseRepository {
    constructor() {
        super('/api/v1/consultations');
    }

    async get(id: number): Promise<TResponseWithData<TConsultation>> {
        return await this._apiClient.get<TConsultation>(id.toString());
    }

    async getList(): Promise<TResponseWithData<TConsultationList>> {
        return await this._apiClient.get<TConsultationList>('list');
    }


    async create(request: TNewConsultation): Promise<TResponseWithData<TNewConsultationResponse>> {
        return await this._apiClient.postOrPut<TNewConsultation, TNewConsultationResponse>('', request, 'POST');
    }

    async updateAnswer(request: TConsultationAnswer): Promise<TResponse> {
        return await this._apiClient.postOrPutEmpty<TConsultationAnswer>('', request, 'PUT');
    }
}

export class ArticlesRepository extends BaseRepository {
    constructor() {
        super('/api/v1/articles');
    }

    async getUserPreviews(userId: number): Promise<TArticlePreview[]> {
        const response = await this._apiClient.get<TArticlePreview[]>(`userPreviews`, { userId: userId.toString() });
        return response.data || [];
    }

    async getUserArticles(userId: string, page: number)
        : Promise<TResponseWithPaginationData<TUserArticle>> {
        return await this._apiClient.getWithPagination<TUserArticle>("userArticles", page, {
            userId: userId
        }, 6);
    }

    async searchArticles(query: string, page: number)
        : Promise<TResponseWithPaginationData<TArticlePreview>> {
        return await this._apiClient.getWithPagination<TArticlePreview>("search", page, {
            query: query
        }, 6);
    }

    async getRecommended(): Promise<TArticlePreview[]> {
        const response = await this._apiClient.get<TArticlePreview[]>(`recommended`);
        return response.data || [];
    }

    async getArticle(id: number): Promise<TResponseWithData<TArticle>> {
        return await this._apiClient.get<TArticle>(id.toString());
    }

    async getFeed(page: number)
        : Promise<TResponseWithPaginationData<TFeedArticle>> {
        return await this._apiClient.getWithPagination<TFeedArticle>("feed", page, {}, 5);
    }

    async updateFavorite(id: number): Promise<TResponse> {
        const request = {
            id: id
        } as TArticleFavoriteRequest

        return await this._apiClient.postOrPutEmpty<TArticleFavoriteRequest>("favorite", request, 'PUT');
    }

    async getFavorite(page: number)
        : Promise<TResponseWithPaginationData<TFavoriteArticle>> {
        return await this._apiClient.getWithPagination<TFavoriteArticle>("favorite", page, {}, 6);
    }

    async create(article: TNewArticle, image?: File): Promise<TResponseWithData<TNewArticleResponse>> {
        const formFields = [
            { key: 'title', value: article.title },
            { key: 'content', value: article.content },
            { key: 'specialization', value: article.specialization },
        ] as TKeyValuePair<string, string | File>[];

        if (image)
            formFields.push({ key: 'file', value: image });

        return await this._apiClient.postFormData("", formFields);
    }

    async update(article: TUpdateArticle): Promise<TResponse> {
        return await this._apiClient.postOrPutEmpty<TUpdateArticle>("", article, 'PUT');
    }
}

export class AuthRepository extends BaseRepository {
    constructor() {
        super('/api/v1/auth');
    }

    async login(request: TLoginRequest): Promise<TResponseWithData<TLogin>> {
        return await this._apiClient.postOrPut<TLoginRequest, TLogin>("login", request, 'POST');
    }

    async register(request: TRegistraionRequest): Promise<TResponseWithData<TLogin>> {
        return await this._apiClient.postOrPut<TRegistraionRequest, TLogin>("register", request, 'POST');
    }
}

export class PrivateFilesRepository extends BaseRepository {
    constructor() {
        super('/api/v1/files');
    }

    async getInfo(): Promise<TResponseWithData<TPrivateFilesInfo>> {
        return await this._apiClient.get<TPrivateFilesInfo>("info");
    }

    async getFiles(page: number)
        : Promise<TResponseWithPaginationData<TPrivateFile>> {
        return await this._apiClient.getWithPagination<TPrivateFile>("list", page, {}, 6);
    }

    async create(article: TNewPrivateFile, file: File): Promise<TResponse> {
        const formFields = [
            { key: 'fileName', value: article.fileName },
            { key: 'type', value: article.type },
            { key: 'file', value: file }
        ] as TKeyValuePair<string, string | File>[];

        return await this._apiClient.postFormData("", formFields);
    }

    async delete(id: number): Promise<TResponse> {
        return await this._apiClient.delete("", {
            id: id.toString()
        });
    }
}

export class UtilsRepository extends BaseRepository {

    constructor() {
        super('/api/v1/utils');
    }

    async getAllEnums(): Promise<TResponseWithData<TEnums>> {
        return await this._apiClient.get<TEnums>("enums");
    }
}

export class CdnFilesRepository extends BaseRepository {

    constructor() {
        super(CdnUrl);
    }

    async getPrivateFile(path: string): Promise<Blob> {
        return await this._apiClient.getBlob("", {
            path: path
        });
    }
}