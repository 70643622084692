import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom"
import '../src/index.css';
import { App } from './App';
import { AuthService, UtilsService } from './infrastructure/services';

const authService = new AuthService();
const utilsService = new UtilsService();

(async () => {
  await initStates();

  ReactDOM
    .createRoot(document.getElementById("root") as HTMLElement)
    .render(
      <BrowserRouter>
        <App />
      </BrowserRouter>
    )
})()

async function initStates() {
  authService.initAuth();
  // await utilsService.initEnums();
}