import { ArticlesRepository, DoctorsRepository, UsersRepository, PrivateFilesRepository } from '../api/BackendRepositories';
import { PageContainer } from '../components/Main';
import { useState, useEffect } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { NotFound, ErrorPage } from './BasePages';
import { SizedImage, RedirectBtn, SubmitBtn, TextWithIcon, DownloadFileBtn, FileDnD, SearchBlock } from '../components/Elements';
import TextareaAutosize from 'react-textarea-autosize';
import { isNothing, convertBytesToUserFormat, toDate, getEnumRuValue, toDateWithTime } from '../infrastructure/utils';
import { SubscribeGlobalState } from "../infrastructure/events"

const privateFilesRepository = new PrivateFilesRepository();

export const Files: React.FC = () => {
    const [privateFilesInfo, setPrivateFilesInfo] = useState<TResponseWithData<TPrivateFilesInfo> | undefined>();

    useEffect(() => {
        privateFilesRepository.getInfo()
            .then(v => setPrivateFilesInfo(v));
    }, []);

    return <PageContainer
        checkAuth={true}
        child={
            privateFilesInfo
                ? privateFilesInfo.hasErrors
                    ? <ErrorPage errors={privateFilesInfo.errors} withBase={false} />
                    : <FilesContainer privateFilesInfo={privateFilesInfo.data!} />
                : <div></div>

        }
    />
}

export const FilesContainer: React.FC<{
    privateFilesInfo: TPrivateFilesInfo
}> = props => {
    const [searchParams, setSearchParams] = useSearchParams()
    const enums = SubscribeGlobalState('enums');
    const fileTypes = enums ? enums['PrivateFileType'] : [];

    const info = props.privateFilesInfo;

    const [values, setValues] = useState([] as TPrivateFile[]);
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);

    useEffect(() => loadValues(), []);

    function loadValues() {
        privateFilesRepository.getFiles(page)
            .then(v => {
                if (v.hasErrors)
                    return;

                const response = v.data;

                setIsLastPage(response.isLastPage);

                if (!response.isLastPage)
                    setPage(page + 1);

                response.values.forEach(f => values.push(f));
                setValues(values);
            });
    }

    return <div>
        <div className='flex flex-row justify-between'>
            <p className='text-[36px] text-sbold'>
                Файлы
            </p>
            <SubmitBtn
                className=''
                colorType='dark'
                sizeType='default'
                textElement={'Добавить'}
                onClick={() => {
                    searchParams.set("popup", "new_file")
                    setSearchParams(searchParams)
                }}
            />
        </div>
        <div className='mt-20'>
            <p className='text-sbold text-[24px]'>
                {`Используется ${convertBytesToUserFormat(info.usedMemory)} из ${convertBytesToUserFormat(info.memoryLimit)}`}
            </p>
            <progress className='mt-2' max={info.memoryLimit} value={info.usedMemory} />
        </div>
        {
            info.usedMemory > 0
                ? <div>
                    <hr className='my-10' />
                    <div>
                        <p className='text-[24px] text-sbold'>
                            Загруженные файлы
                        </p>
                        <div className='mt-5 grid grid-cols-2 gap-4'>
                            {values.map(m => <FileCard key={m.id} enums={fileTypes} privateFile={m} />)}
                        </div>
                        {
                            isLastPage
                                ? <></>
                                : <SubmitBtn
                                    colorType='dark'
                                    className='flex mx-auto mt-10'
                                    textElement={'Показать еще'}
                                    onClick={() => loadValues()}
                                />
                        }
                    </div>
                </div>
                : <></>
        }
    </div>
}


export const FileCard: React.FC<{
    privateFile: TPrivateFile
    enums: TKeyValuePair<string, string>[]
}> = props => {
    const enums = props.enums;
    const privateFile = props.privateFile;

    return <div className='border rounded-xl border-gray p-2 flex flex-row justify-between'>
        <div>
            <p className=''>
                {privateFile.fileName}
            </p>
            <p className='text-[16px]'>
                {getEnumRuValue(enums, privateFile.type)}
            </p>
            <p className='text-light text-[16px] text-gray'>
                {toDateWithTime(privateFile.createdAt)}
            </p>
        </div>
        <div>
            <p>
                <DownloadFileBtn
                    sizeType='short'
                    textElement={'Скачать'}
                    colorType={'dark'}
                    fileName={privateFile.fileName}
                    filePath={privateFile.filePath}
                />
            </p>
            <p>
                <SubmitBtn
                    className='mt-2'
                    sizeType='short'
                    textElement={'Удалить'}
                    colorType={'gray'}
                    onClick={() => {
                        privateFilesRepository.delete(privateFile.id)
                            .then(t => window.location.reload());
                    }}
                />
            </p>
        </div>
    </div>
}