import { ArticlesRepository, DoctorsRepository, UsersRepository } from '../api/BackendRepositories';
import { PageContainer } from '../components/Main';
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { NotFound, ErrorPage } from './BasePages';
import { SizedImage, RedirectBtn, SubmitBtn, TextWithIcon, ApiRequestForm, FileDnD } from '../components/Elements';
import TextareaAutosize from 'react-textarea-autosize';
import { getEnumRuValue, isNothing } from '../infrastructure/utils';
import { SubscribeGlobalState } from "../infrastructure/events"
import Select from 'react-select';

const articlesRepository = new ArticlesRepository();

export const EditArticle: React.FC = () => {
    const { id } = useParams();

    const [articleExists, setArticleExists] = useState<boolean>(false);
    const [articleLoaded, setArticleLoaded] = useState<boolean>(false);
    const [article, setArticle] = useState<TUpdateArticle>({} as TUpdateArticle);
    const [specializationsSelectOptions, setSpecializationsSelectOptions] = useState([] as JSX.Element[]);
    const [isValid, setIsValid] = useState(true);

    const enums = SubscribeGlobalState('enums');
    const specializations = enums ? enums['ArticleSpecializationType'] : [];

    useEffect(() => {
        if (id) {
            const parsedId = parseInt(id);

            if (!isNaN(parsedId)) {
                articlesRepository.getArticle(parsedId)
                    .then(v => {
                        if (v.hasErrors) {
                            setArticleExists(false);
                        } else {
                            const response = v.data!;
                            const model = {
                                id: parsedId,
                                content: response.content,
                                specialization: response.specialization,
                                title: response.title
                            } as TUpdateArticle

                            setArticle(model);
                            setArticleExists(true);
                        }

                        setArticleLoaded(true);
                    });
            }

            setSpecializationsSelectOptions(specializations.filter(f => f.key != 'All')
                .reverse()
                .map(m => <option key={m.key} value={m.key}>{m.value}</option>));
        }

    }, []);

    if (!id || isNaN(parseInt(id)) || (articleLoaded && !articleExists)) {
        return <NotFound />
    }

    return <PageContainer
        checkAuth={true}
        child={
            <div>
                <div className='flex flex-row justify-between'>
                    <div className='text-[32px] text-sbold'>
                        Изменить статью
                    </div>
                    <SubmitBtn
                        className=''
                        colorType={`${isValid ? 'dark' : 'default'}`}
                        isDisabled={!isValid}
                        textElement={'Сохранить'}
                        onClick={() => {
                            articlesRepository.update(article)
                                .then(r => {
                                    if (r.hasErrors) {
                                        alert(r.errors[0])
                                        return;
                                    }

                                    window.location.pathname = `/articles/${article.id}`;
                                });
                        }}
                    />
                </div>
                <input
                    maxLength={150}
                    className='input-text mt-20 text-[24px] h-[71px] text-sbold'
                    type="text"
                    placeholder='Заголовок'
                    defaultValue={article.title}
                    onChange={(v) => {
                        article.title = v.target.value;
                        updateArticle(article);
                    }}
                />
                <select className='input-text mt-5 text-[20px] text-sbold'
                    onChange={(v) => {
                        article.specialization = v.target.value;
                        updateArticle(article);
                    }}
                >
                    {specializationsSelectOptions}
                </select>
                <TextareaAutosize
                    placeholder='Текст статьи (максимум 4000 символов)'
                    minRows={6}
                    maxLength={4000}
                    defaultValue={article.content}
                    className='input-text mt-5 text-[20px] sbold'
                    onChange={(v) => {
                        article.content = v.target.value;
                        updateArticle(article);
                    }}
                />
            </div>
        }
    />

    function updateArticle(artice: TUpdateArticle) {
        setArticle(artice);
        setIsValid(!isNothing(artice.content) && !isNothing(artice.title));
    }
}