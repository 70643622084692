import { ArticlesRepository, DoctorsRepository, UsersRepository } from '../api/BackendRepositories';
import { PageContainer } from '../components/Main';
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { NotFound, ErrorPage } from './BasePages';
import { SizedImage, RedirectBtn, SubmitBtn, TextWithIcon, ApiRequestForm, FileDnD } from '../components/Elements';
import TextareaAutosize from 'react-textarea-autosize';
import { isNothing } from '../infrastructure/utils';
import { SubscribeGlobalState } from "../infrastructure/events"

const articlesRepository = new ArticlesRepository();

export const NewArticle: React.FC = () => {
    const [article, setArticle] = useState<TNewArticle>({
        specialization: 'Other'
    } as TNewArticle);
    const [articleImg, setArticleImg] = useState<{ file: File, preview: string } | undefined>();
    const [specializationsSelectOptions, setSpecializationsSelectOptions] = useState([] as JSX.Element[]);
    const [isValid, setIsValid] = useState(false);

    const enums = SubscribeGlobalState('enums');
    const specializations = enums ? enums['ArticleSpecializationType'] : [];

    useEffect(() => {
        setSpecializationsSelectOptions(specializations.filter(f => f.key != 'All')
            .reverse()
            .map(m => <option key={m.key} value={m.key}>{m.value}</option>));
    }, []);

    return <PageContainer
        checkAuth={true}
        child={
            <div>
                <div className='flex flex-row justify-between'>
                    <div className='text-[32px] text-sbold'>
                        Новая статья
                    </div>
                    <SubmitBtn
                        className=''
                        colorType={`${isValid ? 'dark' : 'default'}`}
                        isDisabled={!isValid}
                        textElement={'Отправить'}
                        onClick={() => {
                            articlesRepository.create(article, articleImg?.file)
                                .then(r => {
                                    if (r.hasErrors) {
                                        alert(r.errors[0])
                                        return;
                                    }

                                    window.location.replace(`${r.data?.id}`);
                                });
                        }}
                    />
                </div>
                <input
                    maxLength={150}
                    className='input-text mt-20 text-[24px] h-[71px] text-sbold'
                    type="text"
                    placeholder='Заголовок'
                    onChange={(v) => {
                        article.title = v.target.value;
                        updateArticle(article);
                    }}
                />
                <select className='input-text mt-5 text-[20px] text-sbold'
                    onChange={(v) => {
                        article.specialization = v.target.value;
                        updateArticle(article);
                    }}
                >
                    {specializationsSelectOptions}
                </select>
                <TextareaAutosize
                    placeholder='Текст статьи (максимум 4000 символов)'
                    minRows={6}
                    maxLength={4000}
                    className='input-text mt-5 text-[20px] sbold'
                    onChange={(v) => {
                        article.content = v.target.value;
                        updateArticle(article);
                    }}
                />
                <FileDnD
                    accept={{
                        'image/jpeg': [],
                        'image/jpg': [],
                        'image/png': [],
                        'image/webp': [],
                    }}
                    labelText='Добавить изображение'
                    maxSizeBytes={10000000}
                    className='mt-5'
                    onDropAccepted={(files) => updateArticleImg(files)}
                    onDropRejected={(rejections) => console.log('rejections', rejections)}
                />
                <img className="preview mt-5" src={articleImg?.preview} alt="" />
            </div>
        }
    />

    function updateArticle(artice: TNewArticle) {
        setArticle(artice);
        setIsValid(!isNothing(artice.content) && !isNothing(artice.title));
    }

    function updateArticleImg(files?: any) {
        const file = files ? files[0] : undefined;

        if (!file) {
            setArticleImg(undefined);
            return;
        }

        const newImgData = {
            file: file,
            preview: URL.createObjectURL(file)
        };

        setArticleImg(newImgData);
    }
}