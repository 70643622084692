import { ArticlesRepository } from '../api/BackendRepositories';
import { PageContainer } from '../components/Main';
import { useState, useEffect } from "react"
import { SubscribeGlobalState } from "../infrastructure/events"
import { toDate } from "../infrastructure/utils"
import { SizedImage, RedirectBtn, SelectionBtn, TextWithIcon, CustomLink, SubmitBtn, SearchBlock } from '../components/Elements';

const articlesRepository = new ArticlesRepository();

export const Feed: React.FC = () => {
    return <PageContainer
        w='w-[50%]'
        child={
            <div>
                <p className='text-[36px] text-sbold mb-10'>
                    Лента статей
                </p>
                <FeedArticlesBlock />
            </div>
        }
    />
}

const FeedArticlesBlock: React.FC = () => {
    const [values, setValues] = useState([] as TFeedArticle[])
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false)

    useEffect(() => loadValues(), []);

    function loadValues() {
        articlesRepository.getFeed(page)
            .then(v => {
                if (v.hasErrors)
                    return;

                const response = v.data;

                setIsLastPage(response.isLastPage)

                if (!response.isLastPage)
                    setPage(page + 1);

                response.values.forEach(f => values.push(f));
                setValues(values);
            });
    }

    return <div>
        {values
            ? <div className='mt-10'>
                <div>
                    {values.map(m => <ArticleCard article={m} key={m.id} />)}
                </div>
                {
                    isLastPage
                        ? <></>
                        : <SubmitBtn
                            colorType='dark'
                            className='flex mx-auto'
                            textElement={'Показать еще'}
                            onClick={() => loadValues()}
                        />
                }
            </div>
            : <div></div>
        }
    </div>
}

const ArticleCard: React.FC<{ article: TFeedArticle }> = props => {
    const artice = props.article;

    const enums = SubscribeGlobalState('enums');
    const specializations = enums ? enums['ArticleSpecializationType'] : [];
    const specialization = specializations.find(f => f.key == artice.specialization)?.value;

    return <CustomLink
        className='mb-10'
        href={`/articles/${artice.id}`}
        child={
            <div className='mx-auto flex flex-row'>
                {
                    <SizedImage
                        imgWitoutFull={true}
                        imgClassName='object-cover h-[150px] w-[230px] object-top'
                        src={artice.imagePath}
                        templateIfSrcEmpty='/images/article_placeholder.png'
                        fromCdn={true}
                    />
                }
                <div className='ml-10 w-[70%]'>
                    <div className='flex flex-row'>
                        <div className='text-[10px] text-gray tracking-widest uppercase'>{toDate(artice.createdAt)}</div>
                        <div className='text-[10px] text-gray tracking-widest uppercase ml-5'>{specialization}</div>
                    </div>
                    <div className='text-[24px] text-sbold mt-1'>{artice.title}</div>
                    <div className='text-[14px] text-gray mt-1 text-light'>{artice.shortContent}</div>
                </div>
            </div>
        }
    />
}

