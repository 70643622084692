import { PageContainer } from '../components/Main';

export const NotFound: React.FC = () => {
    return <PageContainer child={<div className='text-center'>Страница не найдена</div>} />
}

export const ErrorPage: React.FC<{ withBase: boolean, errors?: TResponseError[] }> = props => {
    return props.withBase
        ? <PageContainer child={<ErrorBlock errors={props.errors} />} />
        : <ErrorBlock errors={props.errors} />
}

export const BaseEmptyPage: React.FC = () => {
    return <PageContainer child={<div></div>} />
}

const ErrorBlock: React.FC<{ errors?: TResponseError[] }> = props => {
    return <div className='text-center'>{props.errors && props.errors.length > 0 ? props.errors[0].text : 'Что-то пошло не так. Попробуйте обновить страницу'}</div>
}